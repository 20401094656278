export const color = {
    background: '#F0F2F5',
    border: '#f5f5f5',
    darkblue: '#001529',
    lightblue: '#40a9ff',
    title: '#262626',
    white: '#ffffff',

    blue: '#1890ff',
    cyan: '#13c2c2',
    gold: '#faad14',
    green: '#52c41a',
    gray: '#bfbfbf',
    magenta: '#eb2f96',
    purple: '#722ed1',
    red: '#f5222d',
    yellow: '#fadb14',
    orange: '#fa8c16',
    lime: '#a0d911',
    volcano: '#fa541c',
}

export const spacing = {
    space4: '4px',
    space8: '8px',
    space16: '16px',
    space24: '24px',
    space32: '32px',
    space48: '48px',
    space80: '80px',
}

export const inputWidth = {
    tiny: '88px',
    small: '144px',
    medium: '200px',
    large: '312px',
    huge: '424px',
}

export const fontSize = {
    size12: '12px',
    size14: '14px',
    size16: '16px',
    size20: '20px',
    size24: '24px',
    size30: '30px',
}

export const fontWeight = {
    weight400: '400',
    weight500: '500',
    weight600: '600',
}
