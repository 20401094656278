import type React from 'react'
import type { FC } from 'react'
import { Card, List, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { bff } from '../../bff'

import { color, fontWeight } from 'theme/tokens'

type BillingUserSummary = {
    cardUsers: number
    pocketOnlyUsers: number
    spendingAdmins: number
    totalBillableUsers: number
    bookkeepers: number
}

const USER_TYPE_LABELS: Record<keyof BillingUserSummary, string> = {
    cardUsers: 'Card Users',
    pocketOnlyUsers: 'Pocket-only (Reimbursement-only) Users',
    spendingAdmins: 'Spending Admins',
    bookkeepers: 'Bookkeepers',
    totalBillableUsers: 'Total Billable Users',
}

const { Text } = Typography

type BillingUsersProps = {
    companyId: string
}

const BillingUsers: FC<React.PropsWithChildren<BillingUsersProps>> = ({ companyId }) => {
    const {
        data: userSummary,
        isLoading,
        error,
    } = bff.billing.getBillableUserSummary.useQuery({ companyId })

    if (isLoading || !userSummary) {
        return (
            <Card style={{ maxWidth: '100%' }}>
                <QuestionCircleOutlined />
                <Text>{error?.message || 'An unknown error occurred.'}</Text>
            </Card>
        )
    }

    return (
        <Card title="Billable Users Summary" style={{ maxWidth: '100%' }}>
            <List
                grid={{ gutter: 4, column: 1 }}
                size="small"
                dataSource={Object.entries(USER_TYPE_LABELS)}
                style={{ paddingRight: 'auto', marginBottom: '1rem', width: '50%', margin: 'auto' }}
                renderItem={([key, label]) => (
                    <BillingUserRoleSummaryItemList
                        itemKey={key}
                        label={label}
                        userSummary={userSummary}
                    />
                )}
            />
        </Card>
    )
}

type BillingUserSummaryItemProps = {
    itemKey: string
    label: string
    userSummary: BillingUserSummary
}

const BillingUserRoleSummaryItemList: FC<React.PropsWithChildren<BillingUserSummaryItemProps>> = ({
    itemKey: key,
    label,
    userSummary,
}) => {
    const isTotalBillableUserLabel = label === USER_TYPE_LABELS.totalBillableUsers
    return (
        <List.Item
            key={key}
            style={{ padding: '0.5rem', borderBottom: '1px solid', borderColor: `${color.gray}80` }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'flex-start',
                    margin: 'auto',
                    width: '100%',
                    fontWeight: isTotalBillableUserLabel
                        ? fontWeight.weight600
                        : fontWeight.weight400,
                }}
            >
                <span style={{ paddingLeft: '1rem' }}>
                    {label}:{' '}
                    {isTotalBillableUserLabel && (
                        <Tooltip title="Includes only card users, pocket-only users, and spending admins.">
                            <InfoCircleOutlined />
                        </Tooltip>
                    )}
                </span>
                <span style={{ paddingRight: '1rem' }}>
                    {userSummary[key as keyof BillingUserSummary]}
                </span>
            </div>
        </List.Item>
    )
}

export default BillingUsers
