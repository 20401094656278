import useSWR from 'swr'
import { fetcher as styxFetcher } from 'services/styx/request'
import { fetcher as beyondFetcher } from 'services/beyond/request'
import { mapKycResponse } from './map-kyc-response'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'

export const useKycInformation = (companyId?: string) => {
    const { companySubscription, isLoading: isCompanySubscriptionLoading } =
        useCompanySubscription(companyId)
    const { kycVerificationDetails, isLoading: isKycVerificationDetailsLoading } =
        useKycVerificationDetails(companyId)
    const { company } = useStyxCompany()

    const hasPlan = Boolean(companySubscription)
    const hasLowOrMediumRiskScore = ['LOW', 'MEDIUM'].includes(company?.riskAssessment?.score || '')

    const { standard: standardWithoutHasProductPlan, highRisk } = mapKycResponse(
        kycVerificationDetails,
        company
    )

    return {
        standard: [
            ...standardWithoutHasProductPlan,
            {
                label: 'Products to be used',
                key: 'productsToBeUsed',
                isMissing: !hasPlan,
                isGbCompany: undefined,
            },
        ],
        highRisk,
        isLoading: isCompanySubscriptionLoading || isKycVerificationDetailsLoading,
        displayHighRisk: !hasLowOrMediumRiskScore,
    }
}

// API Calls

// Beyond
const useCompanySubscription = (companyId?: string) => {
    if (!companyId) return { companySubscription: null, isLoading: false, isError: false }

    const response = useSWR(`rest/v2/billing/subscriptions?companyId=${companyId}`, beyondFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })
    const subscription =
        response.data?.data && response.data.data.length > 0 ? response.data.data[0] : null

    return {
        companySubscription: subscription,
        isLoading: !response.data && !response.error,
        ...response,
    }
}

// Styx
const useKycVerificationDetails = (companyId?: string) => {
    const response = useSWR(
        companyId ? `rest/v2/styx-companies/${companyId}/kyc-verification-details` : null,
        styxFetcher,
        { shouldRetryOnError: false }
    )

    return {
        kycVerificationDetails: response.data,
        isLoading: !response.data && !response.error,
        isError: response.error,
        ...response,
    }
}
