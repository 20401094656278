import { CaseDtoState } from 'bff/moons/generated/cdd-case-manager'
import { getLabelForState } from 'features/kyc-processing-hub/lib/state-labels'

export const INITIAL_OPTIONS: { label: string; value: CaseDtoState }[] = [
    { label: 'Select state', value: CaseDtoState.ReadyForFdd },
]

export const SELECTABLE_OPTIONS: { label: string; value: CaseDtoState }[] = [
    { label: getLabelForState(CaseDtoState.InProgress), value: CaseDtoState.InProgress },
    {
        label: getLabelForState(CaseDtoState.WaitingForCustomer),
        value: CaseDtoState.WaitingForCustomer,
    },
    {
        label: getLabelForState(CaseDtoState.InformationReceived),
        value: CaseDtoState.InformationReceived,
    },
]

export const FIXED_OPTIONS: { label: string; value: CaseDtoState }[] = [
    { label: getLabelForState(CaseDtoState.New), value: CaseDtoState.New },
    { label: getLabelForState(CaseDtoState.FddApproved), value: CaseDtoState.FddApproved },
    { label: getLabelForState(CaseDtoState.FddRejected), value: CaseDtoState.FddRejected },
]

export const ALL_OPTIONS = [...INITIAL_OPTIONS, ...SELECTABLE_OPTIONS, ...FIXED_OPTIONS]
