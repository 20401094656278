import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    FileDoneOutlined,
    FileExclamationOutlined,
    HourglassOutlined,
    InboxOutlined,
    InfoCircleOutlined,
    SolutionOutlined,
} from '@ant-design/icons'
import { Skeleton, Space, Tag } from 'antd'
import { bff } from '../bff-hooks'
import { useKycDetailContext } from '../context'
import type { CaseDtoState } from 'bff/moons/generated/cdd-case-manager'
import { getLabelForState } from '../lib/state-labels'

export const StatusBar = () => {
    const { caseId } = useKycDetailContext()
    const { data, isLoading } = bff.statusBar.getCaseStatus.useQuery({ caseId })

    if (isLoading) {
        return <SkeletonTags />
    }

    return (
        <Space>
            <CaseStatusTag caseState={data?.caseState} />
            <StpStatusTag hasStpRun={data?.hasStpRun} />
            {data?.assignedUser && <AssigneeTag assignedUser={data?.assignedUser} />}
        </Space>
    )
}

export const StpStatusTag = ({ hasStpRun }: { hasStpRun?: 'done' | 'notRun' }) => {
    const content = {
        done: {
            icon: <FileDoneOutlined />,
            message: 'Checks run',
            color: 'blue',
        },
        notRun: {
            icon: <FileExclamationOutlined />,
            color: 'warning',
            message: 'No checks run',
        },
    }

    const { icon, color, message } = hasStpRun ? content[hasStpRun] : content.notRun

    return (
        <Tag icon={icon} color={color}>
            {message}
        </Tag>
    )
}

const CaseStatusTag = ({ caseState }: { caseState?: CaseDtoState }) => {
    if (!caseState) {
        return null
    }

    const content: Record<CaseDtoState, { icon: JSX.Element; message: string; color: string }> = {
        New: {
            icon: <InboxOutlined />,
            message: getLabelForState('New'),
            color: 'default',
        },
        ReadyForFdd: {
            icon: <FileExclamationOutlined />,
            message: getLabelForState('ReadyForFdd'),
            color: 'warning',
        },
        InProgress: {
            icon: <SolutionOutlined />,
            message: getLabelForState('InProgress'),
            color: 'blue',
        },
        WaitingForCustomer: {
            icon: <HourglassOutlined />,
            message: getLabelForState('WaitingForCustomer'),
            color: 'default',
        },
        InformationReceived: {
            icon: <InfoCircleOutlined />,
            message: getLabelForState('InformationReceived'),
            color: 'success',
        },
        FddRejected: {
            icon: <CloseCircleOutlined />,
            message: getLabelForState('FddRejected'),
            color: 'error',
        },
        FddApproved: {
            icon: <CheckCircleOutlined />,
            message: getLabelForState('FddApproved'),
            color: 'success',
        },
    }

    const { icon, color, message } = content[caseState]

    return (
        <Tag color={color} icon={icon}>
            {message}
        </Tag>
    )
}

const AssigneeTag = ({ assignedUser }: { assignedUser?: string }) => {
    if (!assignedUser) {
        return null
    }

    return <Tag icon={<SolutionOutlined />} color="default">{`Assigned to ${assignedUser}`}</Tag>
}

const SkeletonTags = () => (
    <Space>
        <Skeleton.Node active style={{ width: '100px', height: '22px' }} />
        <Skeleton.Node active style={{ width: '100px', height: '22px' }} />
    </Space>
)
