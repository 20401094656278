// @ts-check

/**
 * @type {import("./config.type").Config}
 */
export const config = {
    environment: 'staging',
    auth: {
        oauth: {
            clientId: 'e9564d6f-555f-4778-93e9-b5627477e534',
        },
        smsOtp: '123456',
    },
    endpoints: {
        api: 'https://api.staging.pleo.io', // run deimos remotely
        // api: 'http://localhost:8083', // run deimos locally
        ariel: 'https://api.staging.pleo.io',
        auth: 'https://auth.staging.pleo.io',
        // auth: 'http://localhost:8082',
        beyond: 'https://beyond.staging.pleo.io',
        bff: '__INJECTED__',
        caliban: 'https://caliban.staging.pleo.io',
        callisto: 'https://callisto.staging.pleo.io',
        calypso: 'https://api.staging.pleo.io/partner-portal',
        cupid: 'https://cupid.staging.pleo.io',
        dionysus: 'https://dionysus.staging.pleo.io/',
        eirene: 'https://api.staging.pleo.io/',
        oberon: 'https://oberon.staging.pleo.io',
        phobos: 'https://phobos.staging.pleo.io',
        prospero: 'https://prospero.staging.pleo.io',
        skoll: 'https://skoll.staging.pleo.io',
        stripe: 'https://dashboard.stripe.com/test',
        telesto: 'https://telesto.staging.pleo.io',
        pandora: 'https://api.staging.pleo.io/rest/v1/commercial-tooling',
        styx: 'https://api.staging.pleo.io',
        exportDispatcher: 'https://api.staging.pleo.io',
        metis: 'https://metis.staging.pleo.io/rest',
        phoebe: 'https://phoebe.staging.pleo.io',
        themis: 'https://api.staging.pleo.io',
        customervulnerabilitytracker: 'https://customer-vulnerability-tracker.staging.pleo.io',
        juliet: 'https://juliet.staging.pleo.io',
        accend: 'https://dashboard.staging.withaccend.com',
    },
    bugsnag: {
        apiKey: 'ae0c2cf439c08d0a7a600ccaaad77160',
        releaseStage: 'staging',
    },
    grafana: {
        url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/239036554ad6e12a0ed4d6349a4f7a34',
    },
    segment: {
        key: 'NSbydU5IReEaWGgk4BPxPzgdRUd4ibxf',
    },
    testFlight: {
        appUrl: 'https://testflight.apple.com/join/s46ddtw6',
    },
    demoWebApp: {
        url: 'https://app.staging.pleo.io',
    },
    demoAccounts: {
        useSingleAccountModal: false,
    },
}
