import { FC, useCallback, useEffect } from 'react'
import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Empty,
    Layout,
    Result,
    Row,
    Statistic,
    Typography,
    notification,
} from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import type { Company360Overview, EntitlementsOverview } from 'bff/moons/generated/pandora-v1'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BillingInformation } from '../../components/billing-information'
import { ExternalConnections } from '../../components/external-connections'
import { MetabaseLinks } from '../../components/metabase-links'
import { CompanyStatus } from '../../components/company-status'
import { HeaderStatistics } from '../../components/header-statistics'
import { Header } from '../../components/header'
import { DatabaseOutlined } from '@ant-design/icons'
import { AuditLog } from '../../components/audit-log'
import { useCompany360 } from '../../api/use-company-360'
import { formatMoney, formatMoneyIntl } from 'utils/money'
import { Organization } from '../../components/organization'
import { useGetCompany } from '../../api/use-company'
import { BankAccount } from '../../components/bank-account'
import { CompanyTeams } from '../../components/company-teams'
import { ExportHistory } from '../../components/export-history'
import { useCompanyCashback } from '../../api/use-company-cashback'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { KnowYourCustomerSummary } from '../../components/know-your-customer-summary'
import { DeimosMigrationStatus } from '../../components/deimos-migration-status'
import { MostRecentInvoice } from '../../components/most-recent-invoice'
import { bff } from '../../bff'
import { OnboardingJourneyDetails } from '../../components/onboarding-journey-details'
import { CardLimitPermissionsStatus } from '../../components/card-limit-permissions-status'

const METABASE_LINK = 'https://metabase.pleo.io/'

const Entitlements: FC<{ entitlements?: EntitlementsOverview }> = ({ entitlements }) => {
    return entitlements?.baseEntitlements.length || entitlements?.overrideEntitlements.length ? (
        <>
            <Typography.Text strong>Base entitlements</Typography.Text>
            <br />
            {entitlements?.baseEntitlements.map((ent, index) => (
                <span key={ent + index}>{`${ent}${
                    index < entitlements?.baseEntitlements.length - 1 ? ', ' : ''
                }`}</span>
            ))}
            {entitlements?.overrideEntitlements.length ? (
                <>
                    <Divider />
                    <Typography.Text strong>Override entitlements</Typography.Text>
                    <br />
                    {entitlements?.overrideEntitlements.map((ent, index) => (
                        <span key={ent + index}>{`${ent}${
                            index < entitlements?.overrideEntitlements.length - 1 ? ', ' : ''
                        }`}</span>
                    ))}
                </>
            ) : null}
        </>
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
}

const PleoReserve: FC<{ company360?: Company360Overview }> = ({ company360 }) => {
    if (!company360?.externalConnections?.pleoReserveAllocated)
        return (
            <Card type="inner" title="Pleo Reserve">
                <Card.Grid style={{ width: '100%' }} hoverable={false}>
                    <Statistic title="Reserve" value={'n/a'} />
                </Card.Grid>
            </Card>
        )

    const { pleoReserveAllocated } = company360.externalConnections

    return (
        <Card type="inner" title="Pleo Reserve">
            <Badge status="default" text={`${formatMoney(pleoReserveAllocated)} allocated`} />
        </Card>
    )
}

const Error: FC<{ error: Error }> = ({ error }) => {
    const navigate = useNavigate()

    return (
        <Result
            status="error"
            title={error.message}
            subTitle="Something went wrong. Please try again or contact Team Backoffice Support Experience if the problem persists."
            extra={
                <Button onClick={() => navigate('/customer-success/company-360')}>
                    Search for a new customer
                </Button>
            }
        />
    )
}

const gridStyle: React.CSSProperties = {
    width: '50%',
}

const Cashback = () => {
    const { id: companyId } = useParams()
    const { totalCashbackAmount, currentMonthCashbackAmount } = useCompanyCashback()
    const hasHydra = useHasPermissions(['hydra'])
    const hasBeyond = useHasPermissions(['beyond'])
    const hasCashback = hasHydra || hasBeyond

    return (
        <Card
            type="inner"
            title="Cashback"
            extra={
                hasCashback && (
                    <Link to={`/customer-success/cashback/${companyId}`}>View full cashback</Link>
                )
            }
        >
            <Card.Grid style={gridStyle} hoverable={false} data-testid="cashback-current-month">
                <Statistic
                    title="This month"
                    value={
                        formatMoneyIntl(currentMonthCashbackAmount, { hideCurrency: true }) || ''
                    }
                    suffix={currentMonthCashbackAmount?.currency}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} hoverable={false} data-testid="cashback-total">
                <Statistic
                    title="All time"
                    value={formatMoneyIntl(totalCashbackAmount, { hideCurrency: true }) || ''}
                    suffix={totalCashbackAmount?.currency}
                />
            </Card.Grid>
        </Card>
    )
}

export const Company360Details = () => {
    const { company360, error } = useCompany360()
    const { id: companyId } = useParams()
    const { company } = useGetCompany(companyId)
    const { data: subscriptionPlan } = bff.companyDetails.getSubscriptionPlan.useQuery({
        params: { companyId },
    })

    const { data: lastThreeInvoicesValue } =
        bff.lastThreeInvoicesValue.getLastThreeInvoicesValue.useQuery({ companyId })

    const loading = !company360 && !error

    const copyCompanyInfoToClipboard = useCallback(() => {
        const data = [
            `Company ID: ${companyId}`,
            `Company Name: ${company360?.companyOverview?.companyName ?? 'unknown'}`,
            `Size: ${company360?.usersDetails?.billedUsersCount ?? 'unknown'}`,
            `Market: ${company360?.companyOverview?.companyCountryCode ?? 'unknown'}`,
            `Last Three Month Average Invoice: ${
                formatMoneyIntl(lastThreeInvoicesValue?.value, {
                    isMinorUnits: true,
                }) ?? 'unknown'
            }`,
            `Plan: ${subscriptionPlan ?? 'unknown'}`,
        ].join('\n')

        navigator.clipboard.writeText(data)
        notification.success({ message: 'Company info copied to clipboard' })
    }, [company360, subscriptionPlan, companyId, lastThreeInvoicesValue])

    const CopyCompanyInfoButton = () => (
        <Button type="link" disabled={loading} onClick={copyCompanyInfoToClipboard}>
            Copy summary
        </Button>
    )

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {error && <Error error={error} />}
            {!error && (
                <PageContentLayout>
                    <Header />
                    <Layout>
                        <Layout.Content>
                            <KnowYourCustomerSummary companyId={companyId} />
                            <HeaderStatistics
                                loading={loading}
                                customer={company360}
                                lastThreeInvoicesValue={lastThreeInvoicesValue?.value ?? undefined}
                            />
                            <Row gutter={16} style={{ padding: '0 24px 16px 0' }}>
                                <Col span={12} style={{ height: '100%' }}>
                                    <Cashback />
                                </Col>
                                <Col span={12} style={{ height: '100%' }}>
                                    <PleoReserve company360={company360} />
                                </Col>
                            </Row>
                            <ExternalConnections companyId={companyId} />
                            <OnboardingJourneyDetails companyId={companyId} />
                            <Card
                                title="Metabase shortcuts"
                                style={{ margin: '0 24px 24px 0' }}
                                type="inner"
                                extra={
                                    <Button
                                        type="link"
                                        href={METABASE_LINK}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                    >
                                        Open Metabase
                                        <DatabaseOutlined />
                                    </Button>
                                }
                            >
                                <MetabaseLinks companyName={company?.name || ''} />
                            </Card>
                            <Card
                                title="Entitlements"
                                type="inner"
                                style={{ margin: '0 24px 24px 0' }}
                            >
                                <Entitlements
                                    entitlements={
                                        company360?.externalConnections?.entitlementChanges
                                    }
                                />
                            </Card>
                            <Card
                                title="Audit log"
                                type="inner"
                                style={{ margin: '0 24px 24px 0' }}
                            >
                                <AuditLog companyId={companyId} />
                            </Card>
                        </Layout.Content>
                        <Layout.Sider width="25%" style={{ background: 'none' }}>
                            <Card
                                title="Company"
                                type="inner"
                                style={{ margin: '0 0 24px 0' }}
                                extra={CopyCompanyInfoButton()}
                            >
                                <CompanyStatus
                                    companyId={companyId}
                                    company360={company360}
                                    loading={loading}
                                />
                            </Card>
                            <Card
                                type="inner"
                                title="Most recent invoice breakdown"
                                style={{ marginBottom: '24px' }}
                            >
                                <MostRecentInvoice companyId={companyId} />
                            </Card>
                            <Card title="Billing" type="inner" style={{ marginBottom: '24px' }}>
                                <BillingInformation companyId={companyId} />
                            </Card>
                            <Card
                                type="inner"
                                title="Organization"
                                style={{ marginBottom: '24px' }}
                            >
                                <Organization />
                            </Card>
                            <Card
                                type="inner"
                                title="Bank Account details"
                                style={{ marginBottom: '24px' }}
                            >
                                <BankAccount
                                    bankAccount={company360?.bankAccount}
                                    loading={loading}
                                />
                            </Card>
                            <DeimosMigrationStatus companyId={companyId} />
                            <ExportHistory />
                            <CompanyTeams companyId={companyId} />
                            <CardLimitPermissionsStatus companyId={companyId} />
                        </Layout.Sider>
                    </Layout>
                </PageContentLayout>
            )}
        </>
    )
}
