import { CaseDtoState } from 'bff/moons/generated/cdd-case-manager'

export const CASE_STATE_LABELS: Record<CaseDtoState, string> = {
    [CaseDtoState.New]: 'Sign Up in Progress',
    [CaseDtoState.InProgress]: 'In Progress',
    [CaseDtoState.WaitingForCustomer]: 'Waiting for Customer',
    [CaseDtoState.InformationReceived]: 'Information Received',
    [CaseDtoState.ReadyForFdd]: 'Ready for FDD',
    [CaseDtoState.FddApproved]: 'FDD Approved',
    [CaseDtoState.FddRejected]: 'FDD Rejected',
}

export function getLabelForState(state: CaseDtoState): string {
    return CASE_STATE_LABELS[state]
}
